#root {
  width: 50%;
  margin: 0 auto;
}

.App {
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.main {
  height: 100%;
}

.siteLogo{
  float: left;
}

.siteLogoContainer{
  width: 770px;
}

.linkContainer{
  width: 770px;
  margin: 0 auto;
}

.sideNav {
  width: 770px;
  padding: 8px 0;
  min-height:100%;
  margin: 0 auto;
}

.topNav {
  top: 20px;
  left: 10px;
  overflow-x: hidden;
  padding: 8px 0;
}

.topNav-item {
  float: right;
  width: inherit;
  padding: 6px 6px;
  text-align: right;
}

.App-logo {
  width: 200px;
  height: 52px;
}

.sideNav-category {
  width: inherit;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
}

.sideNav-item {
  float: left;
  padding: 14px 14px;
  text-align: right;
  text-decoration: none;
}

.App-link-inactive {
  float: right;
  color: #383838;
  text-decoration: none;
}

.App-link-active {
  color: #383838;
  text-decoration: none;
  font-weight: bold;
}

.contactUs {
  width: 40%;
  float: left;
  margin-right: 5px;
  margin-top: 10px;
  margin-left: 60px;
  text-align: left;
  min-width: 250px;
  padding: 5px;
}

.contactForm {
  float: right;
  width: 53%;
  padding: 5px;
  margin-top: 10px;
  min-width: 400px;
}

.sessionContainer {
  width: 970px;
  margin: 0 auto;
  height: 480px;
}

.formElement{
  width: 300px;
  padding: 10px;
  margin: 0 auto;
  text-align: left;
}

.formElementTextArea{
  width: 400px;
  height: 200px;
  padding: 10px;
  margin: 0 auto;
  text-align: left;
}

.slideShowContainerRight {
  float: right;
  width: 70%;
  padding-right: 200px;
  border: 1px solid #383838;
  padding: 5px;
  margin-top: 10px;
}

.slideShowContainerLeft {
  float: left;
  width: 60%;
  padding-right: 200px;
  border: 1px solid #383838;
  padding: 5px;
  margin-top: 20px;
  margin-left: 15px;
}

.sessionDescriptionRight {
  width: 35%;
  height: inherit;
  margin-left: -100%;
  float: right;
  text-align: left;
}

.sessionDescriptionLeft {
  width: 25%;
  height: inherit;
  float: left;
  margin-right: 5px;
  text-align: left;
  min-width: 250px;
  margin-left: 20px;
}

.slideShowContainerLeftAbout {
  float: left;
  width: 50%;
  padding: 5px;
  margin-top: 10px;
}

.sessionDescriptionRightAbout {
  width: 45%;
  height: inherit;
  float: left;
  margin-right: 5px;
  margin-top: 10px;
  text-align: left;
  min-width: 250px;
}

.logoContainer {
  font-size: 22px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineContainer { 
  float: left;
}

.line {
  width: 25px;
  height: 3px;
  margin-bottom: 6px;
}

.underLine {
  width: inherit;
  height: 3px;
  margin: 2px 0px;
}

.about {
  width: 427px;
  height: 640px;
  margin: 6px 0px;
  padding: 4px;
  border: 1px solid #383838;
}

.headingContainer {
  float: left;
  padding: 0px 10px;
}

.heading {
  float: left;
  padding: 10px 6px;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.pricingHeading{
  text-align: center;
  font-weight: bold;
}

.pricingDesc{
  margin: 10px 0px;
}

.bottomDiv{
  width: 960px;
  margin: 0 auto;
}

.bottomDivHeading{
  margin: 0 auto;
}

.bottomCardContainer{
  margin: 0 auto;
  width: 960px;
}

.bottomCard{
  text-align: left;
  float: left;
  border: 1px solid #383838;
  width: 440px;
  height: 500px;
  margin: 24px 18px;
  background-color: #f9f9f9;
}

.testimonialPic{
  float: left;
  margin: 20px;
}

.testimonialCopy{
  float: left;
  margin: 0px 20px;
}