body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/transp_bg.png);
  background-attachment: fixed;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: relative;
  right: 0px;
  top: 0px;
  z-index: 9999;
  background: #fff;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Menu */

.menu {
  width: 20%;
  position: fixed;
  right: 20px;
  top: 40px;
  display: block;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.menu ul {
  position: relative;
  top: 0px;
  font-size: 24px;
  padding: 0px;
}
.menu li {
  list-style: outside none none;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer;
}
.menu li:hover {
  color: #ff0000;
}

